<template>
	<div class="topic-container">
		<div class="banner-box">
			<img
				class="banner-img"
				src="https://res.metaera.hk/resources/assets/images/zt/zt_banner_400.jpeg"
			/>
		</div>
		<div class="zhuanti_box padding_120 flex justify_content_sb">
			<div class="MainLeft_760">
				<div class="zhuanti_news" v-if="hotRecommendList.length > 0">
					<div class="topTitle"><span class="maincolor">热门专题</span></div>
					<div class="topBox">
						<wzitem
							v-for="(items, index) in hotRecommendList"
							:item="items"
							:key="index"
						></wzitem>
					</div>
				</div>
				<div>
					<hotTopics title="近期专题"></hotTopics>
				</div>
			</div>
			<div class="MainRight_400">
				<module-part type="1"> </module-part>
				<module-part type="4"> </module-part>
				<module-part type="5"> </module-part>
			</div>
		</div>
	</div>
</template>
<script>
import { dexRecommendList } from "@/api/home";
import wzitem from "@/components/pc/wzitem.vue";
import modulePart from "@/components/modulePart.vue";
import hotTopics from "@/components/hotTopics.vue";
export default {
	name: "topic",
	components: {
		wzitem,
		modulePart,
		hotTopics,
	},
	data() {
		return {
			hotRecommendList: [],
		};
	},
	mounted() {
		this.getDexRecommendList();
	},
	methods: {
		// 获取精选快讯
		getDexRecommendList() {
			// let res = JSON.parse(sessionStorage.getItem("homeflashList"));
			// if (res != null && res.code == 200) {
			// 	this.hotTopicsList = res.data.hotLists;
			// }
			dexRecommendList({})
				.then((res) => {
					// sessionStorage.setItem("homeflashList", JSON.stringify(res));
					if (res.code == 200) {
						console.log("res------", res);
						this.hotRecommendList = res.data;
					}
				})
				.catch((error) => {});
		},
	},
};
</script>

<style scoped>
.banner-box {
	width: 100%;
	height: clamp(200px, calc(100vw * 400 / 1440), 400px);
	padding-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
	padding-bottom: clamp(20px, calc(100vw * 30 / 1440), 30px);
	background-image: url(https://res.metaera.hk/resources/assets/images/jianbian.png);
	background-size: cover;
	background-position: center;
	overflow: hidden;
}
.banner-img {
	display: block;
	margin: 0 auto;
	border-radius: 20px;
	width: clamp(200px, calc(100vw * 1200 / 1440), 1200px);
	height: clamp(200px, calc(100vw * 400 / 1440), 400px);
}
.zhuanti_box {
	padding-top: clamp(30px, calc(100vw * 40 / 1440), 40px);
}
.zhuanti_news {
	margin-bottom: clamp(30px, calc(100vw * 50 / 1440), 50px);
}
.topTitle {
	box-sizing: border-box;
	height: calc(100vw * 40 / 1440);
	padding-bottom: clamp(16px, calc(100vw * 20 / 1440), 20px);
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.topTitle > span {
	font-size: clamp(16px, calc(100vw * 20 / 1440), 20px);
	line-height: 1;
	font-weight: 900;
}
</style>
